
import './App.css';
import { Route, Routes } from 'react-router';
import { Container, Navbar, Nav } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import logo from './Images/logo.webp'

//Component Import
import Home from './Components/Home';
import DuckSearch from './Components/DuckSearch';
import BuyDuck from './Components/BuyDuck';
import Merch from './Components/Merch';
import Admin from './Components/Admin/Admin';
import NewDuck from './Components/Admin/NewDuck';
import DuckDetails from './Components/DuckDetails';
import DeleteDuck from './Components/Admin/DeleteDuck';
import UpdateDuck from './Components/UpdateDuck';
import NewMerch from './Components/Admin/NewMerch';
import EditMerch from './Components/Admin/EditMerch';
import EditMerchForm from './Components/Admin/EditMerchForm';
import EditDuck from './Components/Admin/EditDuck';
import NewStoreDuck from './Components/Admin/NewStoreDuck';
import EditDuckForm from './Components/Admin/EditDuckForm';
import Footer from './Components/Footer';




function App() {
  return (
    <div className="App">

      <div>
      <Navbar className='navbar'  expand="lg" >
        <Container>
          <Navbar.Brand href='/'>
            <div className='nav-logo-container'>
            <img href='/' id='nav-logo' src={logo} alt='Duck Logo'/>
            </div>
          </Navbar.Brand>
          {/* <Navbar.Brand  className='text-light' href='/'>Get Ducked</Navbar.Brand> */}
          <Navbar.Toggle className='text-light' aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className='text-white' id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className='text-light nav-text' href="/duck-search">Track My Duck</Nav.Link>
              <Nav.Link className='text-light nav-text' href="/buy-duck">Buy A Trackable Duck</Nav.Link>
              <Nav.Link className='text-light nav-text' href="/merch">Get Ducked Merch</Nav.Link>
              <Nav.Link className='text-light nav-text' href="/about">About</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </div>
      <div>
        <Routes>
          <Route path='/' element={<DuckSearch />} />
          <Route path='/about' element={<Home />} />
          <Route path='/duck-search' element={<DuckSearch />} />
          <Route path='/buy-duck' element={<BuyDuck />} />
          <Route path='/merch' element={<Merch />} />
          <Route path='/27w006gmguouyva9wrn89x3qp680' element={<Admin />} />
          <Route path='/52er7b7dbbs2b0f6ocgnh89ugvny' element={<NewDuck />} />
          <Route path='/n851z1og74jkg7zl3f1cd50jdbjj' element={<DeleteDuck />} />
          <Route path='/497bb37q6a1slpkmj9u5i759gnfb' element={<NewMerch />} />
          <Route path='/no457l5qt8qvg4i057e8bmx4plc4' element={<EditMerch />} />
          <Route path='/no457l5qt8qvg4i057e8bmx4plc4/:id' element={<EditMerchForm />} />
          <Route path='/260ift6zhe4gn9th18d4p6fw1b71/:id' element={<EditDuckForm />} />
          <Route path='/95l18d2fhy0ioxv41tj7lgb29s77' element={<EditDuck />} />
          <Route path='/bkhbqax36kuro4uzogj78c9axox0' element={<NewStoreDuck />} />
          <Route path='/duck-search/:id' element={<DuckDetails />} />
          <Route path='/update-duck/:id' element={<UpdateDuck />} />
        </Routes>
        
      </div>
     <Footer />
    </div>
    
  );
}

export default App;
