import { useNavigate, useParams } from "react-router"
import { useState, useEffect } from "react"
import { getGeocode, getLatLng } from "use-places-autocomplete"
import { useLoadScript } from "@react-google-maps/api"

export default function UpdateDuck() {
    //Variables
    const today = new Date().toDateString()
    const navigate = useNavigate()
    const fileInput = document.getElementById("image")
    const { id } = useParams()
    // const URL = `${process.env.REACT_APP_BACKEND_URL}/ducks/${id}`
    const URL = `https://duckback.travisty-creations.com/ducks/${id}`
    const submitButton = document.getElementById('submit')
    

    //Set date input value to today

    //Google Stuff Initialize
    const { isLoaded } = useLoadScript({
        // googleMapsApiKey: process.env.REACT_APP_GOOG_API_KEY,
        googleMapsApiKey: "AIzaSyBnPKLmyX8dL0TkP04QVZ8lsl3loipDinM",
        libraries: process.env.libraries
    })

    //State
    const [duck, setDuck] = useState({})
    const [disabled, setDisabled] = useState(false)

    //Fetch This Duck
    const handleFetch = async () => {
        try {
            const response = await fetch(URL)
            const foundDuck = await response.json()
            setDuck(foundDuck)

        } catch (err) {
            console.log("Something horrendous happened when fetching the duck", err)
        }
    }
    useEffect(() => {
        handleFetch()
    }, [])

    //Functions

    const handleSubmit = async (e) => {
        e.preventDefault()
        
        submitButton.innerText = "Please Wait..."
        setDisabled(true)
        //Get upload url for image upload to s3 bucket
        const { url } = await fetch("https://duckback.travisty-creations.com/tempurl").then(res => res.json())
        //Upload image file to aws
        const imageFile = e.target[2].files[0]
        
        try {
            await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                body: imageFile
            })
        } catch (err) {
            console.log('Something horrendously wrong happened when uploading the image', err)
        }


        let date = new Date(`${e.target[1].value}T00:00:00`).toLocaleDateString()
        let city = e.target[0].value
        //imageUrl is the upload url generated from aws, but only up to the "?" and this will be used as the src on image tags when this item is called from the database
        let imageUrl = url.split('?')[0]
        let caption = e.target[3].value
        geoCode(city, date, imageUrl, caption)
    }

    const geoCode = async (city, date, imageUrl, caption) => {


        //Search for appropriate result
        const results = await getGeocode({ address: city })
        //Save Address String for human display
        const cityName = results[0].formatted_address
        //Get geo coordinates
        const { lat, lng } = await getLatLng(results[0])
        //Add the new location to current array
        duck.locations.push({ destination: cityName, dateFound: date, coords: { lat: lat, lng: lng }, image: { url: imageUrl, caption: caption } })
        createItem(duck)
    }

    const createItem = async (data) => {
        const options = {
            method: "PUT",
            body: JSON.stringify(data),
            mode: "cors",
            headers: {
                "Content-type": "application/json"
            }
        }
        try {
            const updatedDuck = await fetch(URL, options)
            //Send email notification
            handleEmail(data)
            setDisabled(false)
            submitButton.innerText = "Update This Duck!"
            navigate(`/duck-search/${id}`)
            return updatedDuck
        } catch (err) {
            setDisabled(false)
            submitButton.innerText = "Update This Duck!"
            console.log('error creating duck', err)
        }
    }

    const handleEmail = async (data) => {

        const updatedLocation = data.locations[data.locations.length - 1]
        const templateParams = {
            duckName: data.duckName,
            serial: data.serial,
            city: updatedLocation.destination,
            dateFound: updatedLocation.dateFound,
            caption: updatedLocation.image.caption,
            image: updatedLocation.image.url
        }


        window.emailjs.send('service_89pxiht', 'template_ar1n22f', templateParams)
        .then(function(response) {
            console.log("Email Sent", response.status, response.text)
        }, function(error) {
            console.log('Email send failed', error)
        })
    }

    const handleFileSelect = event => {
        if (event.target.files[0].size > 5000000) {
            alert("Please upload a file that is less than 5MB")
            fileInput.value = null
            return
        }
    }

    if (!isLoaded) return <div><h1>Loading Duck Data...</h1></div>

    return <div className="container">

        <div className="card">
            <h1>Where did you find this duck?</h1>
            <div>
                <form onSubmit={handleSubmit}>
                    <ul>
                        <div className="form-item">
                            <li>
                                <input
                                    name="destination"
                                    id="destination"
                                    type="text"
                                    placeholder="eg: Austin, TX"
                                    className="input-field"
                                    required
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <label htmlFor="dateFound">When did you find the duck?</label>
                                <input
                                    name="dateFound"
                                    id="dateFound"
                                    type="date"
                                    placeholder="dd-mm-yyyy"
                                    className="input-field"
                                    defaultValue={today}
                                    required
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <label htmlFor="image">Upload an image of your new friend!</label>
                                <input
                                    name="image"
                                    id="image"
                                    type="file"
                                    className="input-field"
                                    onChange={handleFileSelect}
                                    accept="image/*"
                                    required
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <label htmlFor="caption">Add a Caption!</label>
                                <input
                                    name="caption"
                                    id="caption"
                                    type="text"
                                    placeholder="eg: Here is our duck where we found him on our Jeep!"
                                    className="input-field"
                                    required
                                />
                            </li>
                        </div>
                    </ul>
                    <div className="form-button">
                        <button className="submit-button" id="submit" disabled={disabled}>Update This Duck!</button>
                    </div>
                </form>
            </div>
        </div>

    </div>
}