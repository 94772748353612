import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"


export default function EditMerchForm(){

    const { id } = useParams()
    const navigate = useNavigate()
    const URL = `https://duckback.travisty-creations.com/stores/${id}`
    const [item, setItem] = useState()

     //Fetch Duck Details
     async function handleFetch() {
        try {
            const response = await fetch(URL)
            const foundItem = await response.json()
            setItem(foundItem)
            

        } catch (err) {
            console.log(`Something bad happened when fetching item id ${id}`)
        }
    }

    useEffect(() => {
        handleFetch()
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            title: e.target[0].value,
            storeUrl: e.target[1].value,
            imageUrl: e.target[2].value,
            price: e.target[3].value
        }

        editItem(data)
    }

    const editItem = async (data) => {

        const options = {
            method: "PUT",
            body: JSON.stringify(data),
            mode: "cors",
            headers: {
                "Content-type": "application/json"
            }
        }

        try {
            const editedItem = await fetch(URL, options)
            const parsedItem = await editedItem.json()
            navigate("/no457l5qt8qvg4i057e8bmx4plc4")
            return parsedItem
        } catch(err) {
            console.log("somethign went terribly wrong when editing the item", err)
        }

        

    }





if (!item) return <div className="container">
    <div className="card">
        <h1>Loading Item Data...</h1>
    </div>
</div>

    return <div className="container">
        
        <div className="card">
            <a href="/27w006gmguouyva9wrn89x3qp680"><button className="submit-button">Back to Admin Page</button></a>
            <hr></hr>
            <a href="/no457l5qt8qvg4i057e8bmx4plc4"><button className="submit-button">Edit Merch Items</button></a>
            <h1>Edit Merch Item</h1>
            
            <div>
                <form onSubmit={handleSubmit}>
                    <ul>
                        <div className="form-item">
                            <li>
                                <input
                                    name="title"
                                    id="title"
                                    type="text"
                                    placeholder="Enter The Item Name"
                                    className="input-field"
                                    defaultValue={item.title}
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <input
                                    name="storeUrl"
                                    id="storeUrl"
                                    type="text"
                                    placeholder="Enter the URL for the store item"
                                    className="input-field"
                                    defaultValue={item.storeUrl}
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <input
                                    name="imageUrl"
                                    id="imageUrl"
                                    type="text"
                                    placeholder="Enter the URL for the image"
                                    className="input-field"
                                    defaultValue={item.imageUrl}
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <input
                                    name="price"
                                    id="price"
                                    type="text"
                                    placeholder="Enter the item price or range"
                                    className="input-field"
                                    defaultValue={item.price}
                                />
                            </li>
                        </div>
                        <div className="form-button">
                            <button className="submit-button">Submit de Merch</button>
                        </div>
                    </ul>
                </form>
            </div>
        </div>
    </div>

    
}