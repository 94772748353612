import { useNavigate } from "react-router"

export default function NewStoreDuck(){

    const navigate = useNavigate()

    var initialInput = {
        
    }
    const URL = "https://duckback.travisty-creations.com/buyducks"


    const handleChange = (e) => {
        var edited = e.target.name
        initialInput[edited] = e.target.value
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        createItem(initialInput)
    }

    const createItem = async (data) => {
        const options = {
            method: "POST",
            body: JSON.stringify(data),
            mode: "cors",
            headers: {
                "Content-type": "application/json"
            }
        }



        try {
            console.log('trying')
            const createdItem = await fetch(URL, options)
            const parsedItem = await createdItem.json()
            navigate("/95l18d2fhy0ioxv41tj7lgb29s77")
            return parsedItem
        } catch (err) {
            console.log('error creating duck', err)
        }
    }

    return <div className="container">
        
        <div className="card">
            <a href="/27w006gmguouyva9wrn89x3qp680"><button className="submit-button">Back to Admin Page</button></a>
            <hr></hr>
            <h1>Add A Store Duck</h1>
            
            <div>
                <form onSubmit={handleSubmit}>
                    <ul>
                        <div className="form-item">
                            <li>
                                <input
                                    name="title"
                                    id="title"
                                    type="text"
                                    placeholder="Enter The Item Name"
                                    onChange={handleChange}
                                    className="input-field"
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <input
                                    name="storeUrl"
                                    id="storeUrl"
                                    type="text"
                                    placeholder="Enter the URL for the store item"
                                    onChange={handleChange}
                                    className="input-field"
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <input
                                    name="imageUrl"
                                    id="imageUrl"
                                    type="text"
                                    placeholder="Enter the URL for the image"
                                    className="input-field"
                                    onChange={handleChange}
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <input
                                    name="price"
                                    id="price"
                                    type="text"
                                    placeholder="Enter the item price or range"
                                    className="input-field"
                                    onChange={handleChange}
                                />
                            </li>
                        </div>
                        <div className="form-button">
                            <button className="submit-button">Submit de Duck</button>
                        </div>

                    </ul>
                </form>
            </div>
        </div>
    </div>
}