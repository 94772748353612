import squarelogo from "../Images/squareLogo.png"



export default function Footer() {


    return (
        <footer>

            <div className="socials">
                <ul>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://instagram.com/get_ducked_official?igshid=OGQ5ZDc2ODk2ZA==">
                            <i class="fa-brands fa-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/GetDuckedOfficial?mibextid=9R9pXO">
                            <i class="fa-brands fa-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@get_ducked_official?is_from_webapp=1&sender_device=pc">
                            <i class="fa-brands fa-tiktok"></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noreferrer" href="https://get-ducked.square.site/s/shop">
                            <img className="stupid-img" src={squarelogo}height={44}></img>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="copyright">
                <p>Copyright © 2023 ttguitarnoob-dev</p>
                <a target="_blank" rel="noreferrer" href="https://github.com/ttguitarnoob-dev"><i class="fa-brands fa-github"></i></a>
            </div>
        </footer>
    )
}