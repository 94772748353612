import { useState, useEffect } from "react"


export default function DuckSearch() {

    //Store user input
    let initialInput = ""


    //Page State
    let [duckData, setDuckData] = useState({ serial: "No Results Found" })
    let [display, setDisplay] = useState(<p>Please Enter Your Search</p>)


    //Funcitons

    async function handleFetch() {
        const URL = "https://duckback.travisty-creations.com/ducks"
        const options = {
            method: "GET"
        }
        try {
            const response = await fetch(URL, options)
            const results = await response.json()

            setDuckData(results)

        } catch (err) {
            setDisplay(<p>Something went wrong when fetching duck data, please refresh the page.</p>)
            console.log("Something went wrong when fetching", err)
        }
    }

    //Call handleFetch
    useEffect(() => {
        handleFetch()
    }, [])

    function handleChange(e) {
        initialInput = e.target.value
    }

    function handleSearch(e) {
        e.preventDefault()
        var foundDuck = duckData.find(item => item.serial === initialInput)
        if (!foundDuck) {
            setDisplay(<p>No Results Found</p>)
        } else {
            setDisplay(
            <div>
                <div className="results-display">
                    <a href={`/duck-search/${foundDuck._id}`}>
                        <p>Name: {foundDuck.duckName}</p>
                        <p>Serial: {foundDuck.serial}</p>
                    </a>
                </div>
            </div>
            )
        }

    }

    if (!duckData) return <div className="container">
        <div className="card">
            <h1>Loading Duck Data...</h1>
        </div>
    </div>

    return <div className="container">
        <div className="card">
            <div className="card-background">
                <h2>Duck Search!</h2>
                <p>Welcome to GetDucked! Please enter your duck's serial number below to get started.</p>
                <form onSubmit={handleSearch}>
                    <input className="input-field" onChange={handleChange} type="text" placeholder="Enter Your Duck's Serial Number"></input>
                    <button className="submit-button">Search</button>
                </form>
                <div className="search-results">
                    <h3>Search Results:</h3>
                    {display}

                </div>
            </div>
        </div>
    </div>
}