import { useParams } from "react-router"
import { useState, useEffect, useMemo, useRef, useCallback } from "react"
import { useLoadScript, GoogleMap, Marker, DirectionsRenderer } from "@react-google-maps/api"


export default function DuckDetails() {
    //Load Map API
    const { isLoaded } = useLoadScript({
        // googleMapsApiKey: process.env.REACT_APP_GOOG_API_KEY,
        googleMapsApiKey: "AIzaSyBnPKLmyX8dL0TkP04QVZ8lsl3loipDinM"
    })

    //Map Options
    const center = useMemo(() => ({ lat: 38, lng: -98.5795 }), [])
    const options = useMemo(() => ({
        mapId: "38c61dfe15ae1e12",
        disableDefaultUI: true,
        clickableIcons: false,

    }), [])
    const mapRef = useRef()
    const onLoad = useCallback(map => (mapRef.current = map), [])

    //Duck Data
    const { id } = useParams()
    const [duck, setDuck] = useState({})
    const [directions, setDirections] = useState([])
    const URL = `https://duckback.travisty-creations.com/ducks/${id}`

    //Fetch Duck Details
    async function handleFetch() {
        try {
            const response = await fetch(URL)
            const foundDuck = await response.json()
            setDuck(foundDuck)
            if (foundDuck.locations.length > 1) {
                fetchDirections(foundDuck)
            } else {
                console.log('only 1 destination')
            }

        } catch (err) {
            console.log(`Something bad happened when fetching duck id ${id}`)
        }
    }

    useEffect(() => {
        handleFetch()
    }, [])

    //Directions Renderer

    async function fetchDirections(duckData) {



        if (!duckData) return
        let waypts = []
        {duckData.locations && duckData.locations.map((oneLocation, index) => (
                waypts.push({
                    location: oneLocation.destination,
                    stopover: false
                })
            ))
        }


        const service = new window.google.maps.DirectionsService()
        if (!service) {
            console.log('Waiting on Google Services')
            return
        }
        service.route({
            origin: duckData.locations[0].destination,
            destination: duckData.locations[duckData.locations.length - 1].destination,
            waypoints: waypts,
            optimizeWaypoints: true,
            travelMode: window.google.maps.TravelMode.DRIVING
        },
            (result, status) => {
                if (status === "OK" && result) {
                    setDirections(result)
                }
            }
        )
    }

    //Loading screen...waits for Google API to load before rendering the rest of the page
    if (!isLoaded) return <div className="container">
    <div className="card">
        <h1>Loading Duck Data...</h1>
    </div>
</div>

    return <div className="full-screen-container">
        <div className="container">
            <div className="details-card">
                <h1>Duck Details</h1>
                <p>You've been ducked...Hooray! See just how far this duck has traveled to get to you and where its journey began.</p>
                <p>Name: {duck.duckName}</p>
                <p>Serial: {duck.serial}</p>


                <a href={`/update-duck/${id}`}><button className="submit-button">Update This Duck's Location!</button></a>
            </div>
        </div>
        <div className="map-container">

            {/* Google map component */}
            <GoogleMap
                zoom={4.4}
                center={center}
                mapContainerClassName="map-container"
                options={options}
                onLoad={onLoad}
            >
                {directions && <DirectionsRenderer directions={directions} options={{
                    polylineOptions: {
                        strokeColor: "#00e1f5",
                        strokeWeight: 5,


                    }
                }} />}
                {duck.locations && duck.locations.map((oneLocation, index) => (
                    <Marker key={index} position={oneLocation.coords} icon="https://i.imgur.com/pJa1FHh.png" />
                ))}
            </GoogleMap>
        </div>
        <div className="locations-list">
            <h2>Stops On My Journey</h2>
            <hr></hr>
            <div className="store-inner-container">
                {duck.locations && duck.locations.map((oneLocation, index) => (
                    
                        <div className="store-div" key={index}>
                            <img src={oneLocation.image.url} alt="No Image Uploaded" onerror="this.style.display='none'" className="store-img" />
                            <div className="store-inner-div">
                                <p className="store-p">{oneLocation.destination}</p>
                                <p className="store-p">{oneLocation.dateFound}</p>
                                <p className="store-p">{`"${oneLocation.image.caption}"`}</p>
                            </div>
                        </div>

                    
                ))}
            </div>
        </div>


    </div>
}