export default function Admin() {



    return <div className="container">


        <div className="card">
            <div className="admin-buttons">
                <h1>Hello, Supreme Administrator Jason!</h1>
                <a href="/52er7b7dbbs2b0f6ocgnh89ugvny"><button className="submit-button">New Duck</button></a>
                <a href="/n851z1og74jkg7zl3f1cd50jdbjj"><button className="submit-button">Delete Duck Data</button></a>
                <a href="/497bb37q6a1slpkmj9u5i759gnfb"><button className="submit-button">Manage Store</button></a>
            </div>
        </div>

    </div>
}