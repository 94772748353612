import Promo from "./Promo"
import { useState, useEffect } from "react"

export default function BuyDuck() {

    const [items, setItems] = useState()
    const URL = `https://duckback.travisty-creations.com/buyducks`

    async function handleFetch() {

        const options = {
            method: "GET"
        }
        try {
            const response = await fetch(URL, options)
            const results = await response.json()
            setItems(results)

        } catch (err) {

            console.log("Something went wrong when fetching", err)
        }
    }

    //Call handleFetch
    useEffect(() => {
        handleFetch()
    }, [])


    if (!items) return <div className="container">
        <div className="store-container">
            <h1>Loading Merch Data...</h1>
        </div>
    </div>

    return <div className="container">

        <div className="store-container">
            <h1>Buy A Trackable Duck</h1>
            <p>Choose from our selection of duck varieties!  Each duck comes with a unique serial number that can be tracked.</p>
            <Promo />
            <div className="store-inner-container">
                {items && items.map((oneItem, index) => (

                    <div className="store-div" key={oneItem._id}>
                        <img src={oneItem.imageUrl} alt={oneItem.title} onerror="this.style.display='none'" className="store-img" />
                        <div className="store-inner-div">
                            <p className="store-p">{oneItem.title}</p>
                            <p className="store-p">{oneItem.price}</p>
                            <a target="_blank" rel="noreferrer" href={oneItem.storeUrl} className="store-link">Buy now</a>
                        </div>
                    </div>


                ))}

            </div>
        </div>
    </div >
}