import { useNavigate } from "react-router"

export default function NewDuck() {

    const navigate = useNavigate()

    var initialInput = {
        locations: { destination: "Austin, TX", dateFound: "Origin", coords: { lat: 30.266666, lng: -97.733330 }, image: { url: "https://getduckedimages.s3.us-east-2.amazonaws.com/fta1sicw", caption: "Home Sweet Home!" } }
    }
    const URL = "https://duckback.travisty-creations.com/ducks"


    const handleChange = (e) => {
        var edited = e.target.name
        initialInput[edited] = e.target.value
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        //Check for image file submission
        const imageFile = e.target[2].files[0]
        if (imageFile) {

            //Get upload url for image upload to s3 bucket
            const { url } = await fetch("https://duckback.travisty-creations.com/tempurl").then(res => res.json())

            //Upload image file to aws
            try {
                await fetch(url, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    body: imageFile
                })
            } catch (err) {
                console.log('Something horrendously wrong happened when uploading the image', err)
            }

            //Add created image url to initialInput
            initialInput.locations.image.url = url.split('?')[0]
        }

        createItem(initialInput)

    }

    const createItem = async (data) => {
        const options = {
            method: "POST",
            body: JSON.stringify(data),
            mode: "cors",
            headers: {
                "Content-type": "application/json"
            }
        }
        try {
            const createdDuck = await fetch(URL, options)
            const parsedDuck = await createdDuck.json()
            navigate("/27w006gmguouyva9wrn89x3qp680")
            return parsedDuck
        } catch (err) {
            console.log('error creating duck', err)
        }
    }

    const handleFileSelect = event => {
        if (event.target.files[0].size > 5000000) {
            const file = document.querySelector('#image')
            alert("Please upload a file that is less than 5MB")
            file.value = ''
            return
        }
    }

    return <div className="container">
        <div className="card">
            <h1>Give A Duck His Destiny!</h1>
            <a href="/27w006gmguouyva9wrn89x3qp680"><button className="submit-button">Back to Admin Page</button></a>
            <div>
                <form onSubmit={handleSubmit}>
                    <ul>
                        <div className="form-item">
                            <li>
                                <input
                                    name="duckName"
                                    id="name"
                                    type="text"
                                    placeholder="Enter The Duck's Name"
                                    onChange={handleChange}
                                    className="input-field"
                                    required
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <input
                                    name="serial"
                                    id="serial"
                                    type="text"
                                    placeholder="Enter The Duck's Serial Number"
                                    onChange={handleChange}
                                    className="input-field"
                                    required
                                />
                            </li>
                        </div>
                        <div className="form-item">
                            <li>
                                <label htmlFor="image">Upload an image!</label>
                                <input
                                    name="image"
                                    id="image"
                                    type="file"
                                    className="input-field"
                                    onChange={handleFileSelect}
                                    accept="image/*"
                                />
                            </li>
                        </div>
                        <div className="form-button">
                            <button className="submit-button">Submit de Duck</button>
                        </div>

                    </ul>
                </form>
            </div>
        </div>
    </div>
}