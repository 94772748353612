
import { useState, useEffect } from "react"
import { useNavigate } from "react-router"

export default function EditMerch() {

    const navigate = useNavigate()
    const [items, setItems] = useState()
    const URL = "https://duckback.travisty-creations.com/stores"

    async function handleFetch() {

        const options = {
            method: "GET"
        }
        try {
            const response = await fetch(URL, options)
            const results = await response.json()
            setItems(results)

        } catch (err) {

            console.log("Something went wrong when fetching", err)
        }
    }

    //Call handleFetch
    useEffect(() => {
        handleFetch()
    }, [])

    function handleConfirm(item) {
        const youSure = window.confirm(`Are you sure you want to delete ${item.title}?`)
        if (youSure) {
            console.log("Confirmed, Item Incinerated")
            handleDelete(item._id)
        }
    }

    async function handleDelete(id) {
        const DURL = `${URL}/${id}`

        try {
            const response = await fetch(DURL, { method: "DELETE" })
            const deletedDuck = await response.json()
            //delete image from aws here?
            navigate("/27w006gmguouyva9wrn89x3qp680")
            return deletedDuck

        } catch (err) {
            console.log("Something went horribly wrong when trying to delete that duck:", err)
        }

    }

    if (!items) return <div className="container">
        <div className="card">
            <h1>Loading Merch Data...</h1>
        </div>
    </div>

    return <div className="container">

        <div className="store-container">
            <h1>Edit Merch Items</h1>
            <a href="/27w006gmguouyva9wrn89x3qp680"><button className="submit-button">Back to Admin Page</button></a>
            <hr></hr>
            <div className="store-inner-container">
                {items && items.map((oneItem, index) => (

                    <div className="store-div" key={oneItem._id}>
                        <img src={oneItem.imageUrl} alt={oneItem.title} onerror="this.style.display='none'" className="store-img" />
                        <div className="store-inner-div">
                            <p className="store-p">{oneItem.title}</p>
                            <p className="store-p">{oneItem.price}</p>
                            <a href={`no457l5qt8qvg4i057e8bmx4plc4/${oneItem._id}`} className="store-link">Edit Item</a>
                            <button onClick={() => handleConfirm(oneItem)} className="delete-button">DELETE ITEM</button>
                        </div>
                    </div>


                ))}
            </div>
        </div>
    </div >
}