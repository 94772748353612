import { useEffect } from "react"

export default function Home() {

    

    return <div className="container">
        <div className="card">
            <h1>Welcome to Get Ducked!</h1>
            <p>I was introduced to the "ducking" phenomenon in 2021 and fell in love immediately.  There was nothing more exciting than walking out of a restaurant and seeing a new duck on my Jeep, or walking on a cruise ship deck and finding one cleverly hidden among the decor. It was fun, it was quirky, and I was all about it. I kept wondering how many people had been ducked with this very duck before and how far it had traveled. I wanted to see just how far a duck from Austin, Texas could go as it was passed from person to person.  I had the idea of building a system to track these ducks and see exactly that! Each duck purchased from Get Ducked comes with a unique serial number and identity.  By heading to the Track My Duck page, you can enter the serial number of the duck you found and see everywhere it has traveled so far.  You can even upload a picture, and a journal entry if you want, as you give it a new home. Whether you are seeing how far a duck has traveled, or want to start a new duck on its journey, my hope is that people from all over the world can visit Get Ducked and get a laugh as they follow along with a little rubber duck and its adventures.</p>
            <p>-Jason Jones, GetDucked.org creator</p>
        </div>
    </div>
}