import { useState, useEffect } from "react"
import { useNavigate } from "react-router"


export default function DeleteDuck() {

    const navigate = useNavigate()

    //Store user input
    let initialInput = ""

    //Delete ID
    let deleteId = ""

    const URL = "https://duckback.travisty-creations.com/ducks"

    //Page State
    let [duckData, setDuckData] = useState({ serial: "No Results Found" })
    let [display, setDisplay] = useState(<p>Please Enter Your Search</p>)


    //Funcitons

    async function handleFetch() {

        const options = {
            method: "GET"
        }
        try {
            const response = await fetch(URL, options)
            const results = await response.json()

            setDuckData(results)

        } catch (err) {
            setDuckData({ serial: "No resultss found" })
            console.log("Something went wrong when fetching", err)
        }
    }

    //Call handleFetch
    useEffect(() => {
        handleFetch()
    }, [])

    function handleChange(e) {
        initialInput = e.target.value
    }

    function handleSearch(e) {
        e.preventDefault()

        var foundDuck = duckData.find(item => item.serial === initialInput)
        if (!foundDuck) {
            setDisplay(<p>No Results Found</p>)
        } else {
            deleteId = foundDuck._id
            setDisplay(<div>
                <a target="_blank" rel="noreferrer" href={`/duck-search/${foundDuck._id}`}>
                    <p>Name: {foundDuck.duckName}</p>
                    <p>Serial: {foundDuck.serial}</p>
                </a>
                <div>
                    {foundDuck.locations && foundDuck.locations.map((oneJournal, index) => (
                        <div className="delete-card">
                            <img src={oneJournal.image.url} width="100"></img>
                            <p>{oneJournal.destination}</p>
                            <p>{oneJournal.image.caption}</p>
                            <button className="submit-button" onClick={() => handleUpdateConfirm(foundDuck, index)}>DELETE ENTRY</button>
                        </div>
                        
                        
                    ))}
                </div>
                <button className="submit-button" onClick={() => handleConfirm(foundDuck.duckName)}>DELETE THE WHOLE DUCK</button>
            </div>)
        }

    }

    function handleConfirm(duckName) {
        const youSure = window.confirm(`Are you sure you want to delete ${duckName}?`)
        if (youSure) {
            console.log("Confirmed, Duck Dispelled")
            handleDelete(deleteId)
        }
    }

    function handleUpdateConfirm(duck, index) {
        const youSure = window.confirm(`Are you sure you want to delete the entry for ${duck.locations[index].destination}?`)
        if (youSure) {
            console.log("Confirmed, Entry Eliminated")
            handleDeleteEntry(duck, index)
        }
    }

    async function handleDelete(id) {
        const DURL = URL + `/${id}`

        try {
            const response = await fetch(DURL, { method: "DELETE" })
            const deletedDuck = await response.json()
            //delete images from aws (will require singling out the deleted duck within this function and possibly allowing DELETE request on the aws user profile)
            // for (let i = 0; i < foundDuck.locations.length; i++){
            //     console.log('iiii', foundDuck.locations[i].image.url)
            // }
            navigate("/27w006gmguouyva9wrn89x3qp680")
            return deletedDuck

        } catch (err) {
            console.log("Something went horribly wrong when trying to delete that duck:", err)
        }

    }

    async function handleDeleteEntry(data, index){
        const updatedDuck = data
        const duckUrl = `${URL}/${data._id}`

        updatedDuck.locations.splice(index, 1)

        const options = {
            method: "PUT",
            body: JSON.stringify(data),
            mode: "cors",
            headers: {
                "Content-type": "application/json"
            }
        }

        try {
            const putDuck = await fetch(duckUrl, options)
            navigate('/27w006gmguouyva9wrn89x3qp680')
            return putDuck

        } catch(err) {
            console.log('something devastating happened when deleting the journal entry', err)
        }

    }


    return <div className="container">
        <div className="card">
            <h1>Delete Duck Data</h1>
            <a href="/27w006gmguouyva9wrn89x3qp680"><button className="submit-button">Back to Admin Page</button></a>
            <form onSubmit={handleSearch}>
                <input className="input-field" onChange={handleChange} type="text" placeholder="Enter Your Duck's Serial Number"></input>
                <button className="submit-button">Search</button>
            </form>
            <h3>Duck Serial Search Results:</h3>
            <div>{display}</div>
        </div>
    </div>
}